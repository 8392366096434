import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  formatDigitizedFIRMNames,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import { DisclaimerName } from "../../../../generated/graphql";
import { AuthContext } from "../../../Authorization/AuthContext";
import Disclaimer from "../../../Common/Disclaimer";

export default ({
  accountFIRMData,
}: {
  accountFIRMData?: Array<{ digitized?: Maybe<boolean>; name: string }>;
}) => {
  const { account, isGuest } = useContext(AuthContext);
  const { t } = useTranslation();

  const digitizedFIRMs = accountFIRMData?.filter(firm => firm.digitized) ?? [];

  if (digitizedFIRMs.length === 0) {
    return null;
  }

  const formattedFirmNames = formatDigitizedFIRMNames({
    digitizedFIRMs,
  });

  const disclaimerText = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.FIRM_DIGITIZATION,
    isPublic: isGuest,
    defaultDisclaimers: {
      public: t("disclaimers-digitized-firms", {
        firmNames: formattedFirmNames,
        count: digitizedFIRMs.length,
      }),
      internal: t("disclaimers-digitized-firms", {
        firmNames: formattedFirmNames,
        count: digitizedFIRMs.length,
      }),
    },
  });

  return <Disclaimer message={disclaimerText} />;
};
