import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  getGeometryDisclaimerText,
  getGeometryDisclaimerTranslationKey,
} from "common-client/utils/disclaimerOverrides";
import { GeometryType } from "../../../../generated/graphql";
import { AuthContext } from "../../../Authorization/AuthContext";
import Disclaimer from "../../../Common/Disclaimer";
import { FIRMInfoContext } from "./FIRMInfoContext";

const GeometryDisclaimer = () => {
  const { account, isGuest } = useContext(AuthContext);
  const { queryGeometryType } = useContext(FIRMInfoContext);
  const { t } = useTranslation();

  const geometryType = queryGeometryType ?? GeometryType.POINT;
  const translatedDisclaimer = t(
    getGeometryDisclaimerTranslationKey(geometryType)
  );

  const disclaimerText = getGeometryDisclaimerText({
    firmInfo: { queryGeometryType: geometryType },
    disclaimerOverrides: account?.disclaimerOverrides,
    isPublic: isGuest,
    translatedDisclaimer,
  });

  return <Disclaimer message={disclaimerText} />;
};

export default GeometryDisclaimer;
