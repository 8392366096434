import React, { useContext, useMemo } from "react";
import { DocumentNode } from "graphql";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME } from "common/authorization";
import {
  defaultGuestCertificatesDisclaimer,
  getDefaultBFEOverriddenDisclaimerKey,
  getDefaultBuildingInformationDisclaimerKey,
  getDisclaimerOverride,
} from "common-client/utils/disclaimerOverrides";
import {
  DisclaimerName,
  useUpdateDisclaimerOverrideMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { spacing } from "../../../stitches.config";
import { CURRENT_ADMIN } from "../../Authorization/__queries__";
import { AuthContext } from "../../Authorization/AuthContext";
import { Box } from "../../Common/__styles__/Layout";
import { Button } from "../../Common/Button";
import Divider from "../../Common/Divider";
import { ExpandableList } from "../../Common/ExpandableList";
import { Section } from "../../Common/Layout";
import { Markdown } from "../../Inputs/Markdown";
import { Footer, Header, HeaderTitle } from "../__styles__/Content";
import { DisclaimerForm } from "./__styles__/Disclaimers";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";

const getDefaultBFEOverriddenDisclaimer = ({
  overrideType,
  translationFn,
}: {
  overrideType: { bfe: boolean; dfe: boolean };
  translationFn: ReturnType<typeof useTranslation>["t"];
}) => {
  const translationKey = getDefaultBFEOverriddenDisclaimerKey({
    hasBFEOverride: overrideType.bfe,
    hasDFEOverride: overrideType.dfe,
  });

  if (!translationKey) {
    return null;
  }

  return translationFn(translationKey);
};

const getPublicWebsiteDisclaimers = (
  t: ReturnType<typeof useTranslation>["t"]
) => [
  {
    title: "Elevation Certificate page header disclaimer",
    disclaimerName: DisclaimerName.CERTIFICATE_PAGE,
    defaultDisclaimer: defaultGuestCertificatesDisclaimer({
      accountName: "ACCOUNT_NAME",
    }),
  },
  {
    title: "Public Property Profile file tab disclaimer",
    disclaimerName: DisclaimerName.DOCUMENTS,
    defaultDisclaimer: t("disclaimers-documents", {
      accountName: "ACCOUNT_NAME",
      getHelpLink: "GET_HELP_LINK",
    }),
  },
  {
    title:
      "Public Property Profile flood information coordinates geometry disclaimer",
    disclaimerName: DisclaimerName.POINT_GEOMETRY,
    defaultDisclaimer: t("disclaimers-point-geometry"),
  },
  {
    title:
      "Public Property Profile flood information parcel geometry disclaimer",
    disclaimerName: DisclaimerName.PARCEL_GEOMETRY,
    defaultDisclaimer: t("disclaimers-parcel-determination"),
  },
  {
    title: "Public Property Profile custom digitization/dataset disclaimer",
    disclaimerName: DisclaimerName.FIRM_DIGITIZATION,
    previewContent: t("disclaimers-digitized-firms", {
      firmNames: ["FIRM_NAME"],
      count: 1,
    }),
  },
  {
    title: "Public Property Profile building information disclaimer",
    disclaimerName: DisclaimerName.BUILDING_INFO,
    defaultDisclaimer: t(
      getDefaultBuildingInformationDisclaimerKey({
        certificateIssuedAt: "ISSUED_AT",
      }),
      { issuedAt: "ISSUED_AT" }
    ),
  },
  {
    title: "Public Property Profile SI/SD tab disclaimer",
    disclaimerName: DisclaimerName.SISD,
    defaultDisclaimer: t("disclaimers-sisd"),
  },
  {
    title: "Public Property Profile non-FIRM BFE and DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE_AND_DFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: true,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Public Property Profile non-FIRM BFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: false,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Public Property Profile non-FIRM DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_DFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: false,
        dfe: true,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Public Property Profile non-FIRM BFD disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFD,
    previewContent: t("property-disclaimer-bfd"),
  },
];

const getInternalWebsiteDisclaimers = (
  t: ReturnType<typeof useTranslation>["t"]
) => [
  {
    title:
      "Property Information Panel flood information coordinates geometry disclaimer",
    disclaimerName: DisclaimerName.POINT_GEOMETRY,
    defaultDisclaimer: t("disclaimers-point-geometry"),
  },
  {
    title:
      "Property Information Panel flood information parcel geometry disclaimer",
    disclaimerName: DisclaimerName.PARCEL_GEOMETRY,
    defaultDisclaimer: t("disclaimers-parcel-determination"),
  },

  {
    title: "Property Information Panel custom digitization/dataset disclaimer",
    disclaimerName: DisclaimerName.FIRM_DIGITIZATION,
    previewContent: t("disclaimers-digitized-firms", {
      firmNames: ["FIRM_NAME"],
      count: 1,
    }),
  },
  {
    title: "Property Information Panel building information disclaimer",
    disclaimerName: DisclaimerName.BUILDING_INFO,
    defaultDisclaimer: t(
      getDefaultBuildingInformationDisclaimerKey({
        certificateIssuedAt: "ISSUED_AT",
      }),
      { issuedAt: "ISSUED_AT" }
    ),
  },
  {
    title: "Property Information Panel SI/SD tab disclaimer",
    disclaimerName: DisclaimerName.SISD,
    defaultDisclaimer: t("disclaimers-sisd"),
  },
  {
    title: "Property Information Panel non-FIRM BFE and DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE_AND_DFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: true,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Property Information Panel non-FIRM BFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: false,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Property Information Panel non-FIRM DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_DFE,
    previewContent: getDefaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: false,
        dfe: true,
      },
      translationFn: t,
    })!,
  },
  {
    title: "Property Information Panel non-FIRM BFD disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFD,
    previewContent: t("property-disclaimer-bfd"),
  },
];

export type DisclaimerProps = {
  disclaimerName: DisclaimerName;
  defaultDisclaimer?: string;
  previewContent?: string;
  isPublic: boolean;
  refetchQueries?: Array<{ query: DocumentNode }>;
};

export const Disclaimer = ({
  disclaimerName,
  previewContent,
  defaultDisclaimer = "",
  isPublic,
  //if we expose this to users/managers we will need to add {query: CURRENT_USER} to the refetch queries
  refetchQueries = [{ query: CURRENT_ADMIN }],
}: DisclaimerProps) => {
  const { account } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [updateDisclaimerOverride] = useUpdateDisclaimerOverrideMutation({
    refetchQueries,
    onCompleted: () => {
      addSuccessToast("Your disclaimer was successfully updated.");
    },
    onError: () => {
      addErrorToast(
        "There was a problem updating your disclaimer. Please try again or contact support@withforerunner.com"
      );
    },
  });

  const disclaimerKey = `${disclaimerName}-${isPublic ? "public" : "internal"}`;

  const lastSavedDisclaimer = getDisclaimerOverride({
    disclaimerName,
    isPublic,
    disclaimerOverrides: account?.disclaimerOverrides,
  });

  const useFormProps = useForm<{ [key: string]: string }>({
    defaultValues: {
      [disclaimerKey]: lastSavedDisclaimer ?? "",
    },
  });

  const currentFormValue = useFormProps.watch(disclaimerKey);

  // only allow update if disclaimer has been changed
  const canUpdate =
    (lastSavedDisclaimer !== null || currentFormValue !== "") &&
    lastSavedDisclaimer !== currentFormValue;

  return (
    <DisclaimerForm>
      <FormProvider {...useFormProps}>
        <Markdown
          name={disclaimerKey}
          control={useFormProps.control}
          previewContent={previewContent}
          placeholderContent={defaultDisclaimer}
        />
        {!previewContent && (
          <div>
            <Button
              styleVariant="primary"
              size="medium"
              onClick={useFormProps.handleSubmit(async data => {
                await updateDisclaimerOverride({
                  variables: {
                    data: {
                      disclaimerName,
                      content: data[disclaimerKey] ?? "",
                      isPublic,
                    },
                  },
                });
              })}
              disabled={!canUpdate}
            >
              Update disclaimer
            </Button>
          </div>
        )}
      </FormProvider>
    </DisclaimerForm>
  );
};

export const Disclaimers = () => {
  const { authorized, account } = useContext(AuthContext);
  const { t } = useTranslation();

  const canUpdateAccountDisclaimerOverrides = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "updateDisclaimerOverrides",
  });

  const publicWebsiteDisclaimers = useMemo(
    () => getPublicWebsiteDisclaimers(t),
    [t]
  );

  const internalWebsiteDisclaimers = useMemo(
    () => getInternalWebsiteDisclaimers(t),
    [t]
  );

  return (
    <Box>
      <Header>
        <HeaderTitle>Disclaimer settings</HeaderTitle>
      </Header>
      <Divider />
      <Section style={{ padding: "24px 24px 0px 24px" }} gap={spacing.l}>
        {!canUpdateAccountDisclaimerOverrides ? (
          <InsufficientPermissionsBanner />
        ) : (
          <>
            {account?.publicPortal.enabled && (
              <Section>
                <ExpandableList
                  title="Public website disclaimers"
                  list={publicWebsiteDisclaimers.map(disclaimer => ({
                    title: disclaimer.title,
                    expandableContent: (
                      <Disclaimer
                        disclaimerName={disclaimer.disclaimerName}
                        isPublic={true}
                        previewContent={disclaimer.previewContent}
                        defaultDisclaimer={disclaimer.defaultDisclaimer}
                      />
                    ),
                  }))}
                />
              </Section>
            )}
            <Section>
              <ExpandableList
                title="Internal disclaimers"
                list={internalWebsiteDisclaimers.map(disclaimer => ({
                  title: disclaimer.title,
                  expandableContent: (
                    <Disclaimer
                      disclaimerName={disclaimer.disclaimerName}
                      defaultDisclaimer={disclaimer.defaultDisclaimer}
                      isPublic={false}
                      previewContent={disclaimer.previewContent}
                    />
                  ),
                }))}
              />
            </Section>
          </>
        )}
      </Section>
      <Footer />
    </Box>
  );
};

export default Disclaimers;
