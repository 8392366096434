import React from "react";
import { useTranslation } from "react-i18next";
import { getDefaultBFDOverriddenDisclaimerKey } from "common-client/utils/disclaimerOverrides";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import Disclaimer from "../../../Common/Disclaimer";

const BFDOverriddenDisclaimer = ({ firm }: { firm: FIRMWithWarnings }) => {
  const { t } = useTranslation();

  const disclaimerKey = getDefaultBFDOverriddenDisclaimerKey({ firm });

  if (!disclaimerKey) return null;

  return <Disclaimer message={t(disclaimerKey)} />;
};

export default BFDOverriddenDisclaimer;
