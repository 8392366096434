import React from "react";
import { useTranslation } from "react-i18next";
import {
  getBFEOverrideState,
  getDefaultBFEOverriddenDisclaimerKey,
} from "common-client/utils/disclaimerOverrides";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { AccountPropertyAttribute } from "../../../../generated/graphql";
import Disclaimer from "../../../Common/Disclaimer";
import { Property } from "./types";

const BFEOverriddenDisclaimer = ({
  firm,
  property,
  accountPropertyAttributes,
}: {
  firm: FIRMWithWarnings;
  property: Property;
  accountPropertyAttributes: Array<
    Pick<AccountPropertyAttribute, "id" | "source">
  >;
}) => {
  const { t } = useTranslation();

  const { hasBFEOverride, hasDFEOverride } = getBFEOverrideState({
    firm,
    designFloodElevationRule: property?.designFloodElevation ?? null,
    accountPropertyAttributes,
  });

  const disclaimerKey = getDefaultBFEOverriddenDisclaimerKey({
    hasBFEOverride,
    hasDFEOverride,
  });

  if (!disclaimerKey) return null;

  return <Disclaimer message={t(disclaimerKey)} />;
};

export default BFEOverriddenDisclaimer;
